import React from "react"
import LayOut from "../components/layout"
import Header from "../components/legal/sub/header"
import SEO from "../components/legal/seo"
import { useStaticQuery, graphql } from "gatsby"
import BlocksContent from "../components/blocksContent"
import * as style from "../components/legal/sub/substyle.module.scss"

const PrivacyPolicy = () => {
  const { sanityPrivacyPolicy: data } = useStaticQuery(graphql`
    query PrivacyPolicy {
      sanityPrivacyPolicy {
        header {
          reverse
          _key
          firstColumn {
            _rawColumnContent
          }
          secondColumn {
            _rawColumnContent
          }
          title
        }
        title
        seo {
          ogImage {
            asset {
              url
            }
          }
          pageDescription
          pageKeyWords
          pageTitle
        }
        column {
          _rawColumnContent
        }
      }
    }
  `)

  return (
    <LayOut>
      <SEO />
      <Header data={data.header} />
      <div className={style.blocks}>
        <BlocksContent blocks={data.column._rawColumnContent} />
      </div>
    </LayOut>
  )
}

export default PrivacyPolicy
